<template>
  <div class="new-center">
    <div class="content-left">
      <div style="text-align: left">
        <div class="left-title">新闻中心</div>
        <div class="left-sub-title">NEWS</div>
        <div class="tab-menu">
          <div v-for="(item, i) in tabMenu" :key="i">
          <div v-if="item.level != 0"  class="menu-item" :class="currentTab.num == i?'active-class':''" @click="changeMenu(item,i)">
            <i class="el-icon-caret-right"></i>
            <span>{{ item.name }}</span>
          </div>
          <div v-if="item.children">
            <div v-for="(item2, i2) in item.children" :key="i2">
              <div class="sub-menu">
                <i class="el-icon-caret-right"></i>
                <span>{{ item2.name }}</span>
              </div>
            </div>
          </div> 
        </div>
        </div>
      </div>
    </div>
    <div class="content-right">
      <div class="content-title">
        <span class="title-line">{{ currentTab.name }}</span>
      </div>
      <div class="content-info">
        <div class="content-banner" v-if="(currentTab.num == 0||currentTab.num == 1||currentTab.num == 2) && carouselList.length>0">
          <el-carousel :interval="5000" type="card" height="260px" indicator-position="none">
            <el-carousel-item  v-for="(item,i) in carouselList" :key="i">
              <div style="height: 100%;background: #fff;">
                <img :src="item.thumbnail" alt="" style="width: 100%;height: 200px;">
                <div class="news-title">{{item.newsTitle}}</div>
                <!-- <div class="news-time">{{item.timeType}}</div> -->
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 新闻列表页 -->
        <div>
          <div class="news-list">
            <div v-for="(item,i) in newsList" :key="i" class="news-item" @click="goToDetail(item.newsId)">
              <i class="el-icon-caret-right"></i>
              <span style="margin-left: 3px;">{{ item.newsTitle }}</span>
              <span style="margin-left: 3px; float: right;">{{ item.createTime }}</span>
            </div>
          </div>
          <!-- 分页 -->
          <el-pagination
          v-if="pages >0"
           style="margin-top: 40px;text-align: center;"
            background
            @current-change="handleCurrentChange"
            :page-size="15"
            layout="prev, pager, next"
            :page-count="pages">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsNoticeAll} from "../../../js/api/index";
export default {
  data() {
    return {
      tabMenu: [
        { name: "新闻中心", level: 0,newsCode:0 },
        { name: "最新发布", level: 1,newsCode:4 },
        { name: "集团动态", level: 1,newsCode:1 },
        { name: "子公司新闻", level: 1,newsCode:7 },
        { name: "信息公告", level: 1,newsCode:2 },
        { name: "招租公告", level: 1,newsCode:5 },
        { name: "媒体聚焦", level: 1,newsCode:6 },
      ],
      currentTab: {
        name: "新闻中心",
        num: 0,
      },
      BASE_URL:BASE_URL,
      newsList:[],
      noticeList:[],
      carouselList:[],//轮播新闻数据
      pageInfo:{
        pageNum:1,
        pageSize:15,
        type:1
      },
      pages:1,
    };
  },
  watch: {
    '$route'(to, from) {
      // 在路由发生变化时触发的回调函数
      let type = to.query.type;
      if(type){
        this.currentTab.name = this.tabMenu[type].name;
        this.currentTab.num =type;
        let newsType = this.checkNewsType(type);
        this.pageInfo.pageNum = 1;
        this.pageInfo.type = newsType;
        this.initNewsData();  
      }else{
        this.currentTab={
          name: "新闻中心",
          num: 0,
        }
        this.pageInfo.type = 1;
        this.pageInfo.pageNum = 1;
        this.initNewsData();
      }
    }
  },
  computed: {},
  methods: {
    //判定新闻类型
    checkNewsType(type){
      let newsType = 0;
      switch (type) {
        case '1':
          newsType=4;
        break;
        case '2':
          newsType=1;
        break;
        case '3':
          newsType=7;
        break;
        case '4':
          newsType=2;
        break;
        case '5':
          newsType=5;
        break;
        case '6':
          newsType=6;
        break;
      }
      return newsType;
    },
    changeMenu(item,i){
      this.currentTab.name = item.name;
      this.currentTab.num =i;
      let type = this.$route.query.type;
      if(type == i) return
      this.$router.replace({ query: { type: i } }); // 只能用 name
    },
    //路由跳转新闻详情页
    goToDetail(id){
      this.$router.push({ path: '/newsdetail', query: { id: id } }); // 跳转并传递参数
    },
    splitTime(time){
      // 分割日期和时间
      var splitDateTime = time.split(" ");
      var date = splitDateTime[0];
      var time = splitDateTime[1];
      // 分割年、月、日
      var splitDate = date.split("-");
      var year = splitDate[0];
      var month = splitDate[1];
      var day = splitDate[2];
      return date
    },
    //初始化新闻信息
    initNewsData(){
      getNewsNoticeAll(this.pageInfo).then((res) => {
        let resData = res.data;
        let list = resData.list;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          element.timeType = this.splitTime(element.createTime)
        }
        this.newsList = list;
        this.pages = resData.pages;
        this.carouselList = this.newsList.length>7?this.newsList.slice(0,7):this.newsList;
      });
    },
    checkRouter(){
      let type = this.$route.query.type; // 获取当前路由的查询参数对象  
      if(type){
        this.currentTab.name = this.tabMenu[type].name;
        this.currentTab.num =type;
        let newsType = this.checkNewsType(type);
        this.pageInfo.pageNum = 1;
        this.pageInfo.type = newsType;
        this.initNewsData();
      }else{
        this.currentTab =  {
          name: "新闻中心",
          num: 0,
        };
        this.pageInfo.type = 1;
        this.pageInfo.pageNum = 1;
        this.initNewsData();
      }
    },
    async handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      await this.updateNewsData();
    },
    //分页更新新闻信息
    async updateNewsData(){
      getNewsNoticeAll(this.pageInfo).then((res) => {
        let resData = res.data;
        let list = resData.list;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          element.timeType = this.splitTime(element.createTime)
        }
        this.newsList = list;
      });
    },
  },
  created(){
    this.checkRouter();
  },
  mounted() {
  //  this.initNewsData();
  }
};
</script>

<style lang="scss" scoped>
.new-center {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
}
.content-left {
  width: 28.6%;
  padding-left: 30px;
  padding-right: 0;
  padding-top: 40px;
  padding-bottom: 30vh;
  background: #f7f7f7;
  .left-title {
    font-family: 微软雅黑;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    color: #003f99;
    padding: 0 0 5px 0;
    display: block;
    padding: 0 0 5px 0;
  }
  .left-sub-title {
    font-family: arial;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: #bababa;
    display: block;
  }
  .tab-menu {
    width: 75%;
    margin-top: 10px;
    .sub-menu {
      font-size: 14px;
      color: #666666;
      cursor: pointer;
      padding: 15px 10px;
      padding-left: 30px;
      border-bottom: 1px solid gainsboro;
    }
  }
  .menu-item {
    cursor: pointer;
    padding: 15px 10px;
    font-size: 16px;
    color: #666666;
  }
  .menu-item{
    border-bottom: 1px solid gainsboro;
  }
  .menu-item:hover,.sub-menu:hover{
    color: #003f99;
  }
  .active-class{
    color: #003f99;
  }
}
.content-right {
  width: 71.4%;
  padding-left: 30px;
  padding-right: 0;
  padding-top: 40px;
  padding-bottom: 60px;
  .content-title {
    text-align: left;
    border-bottom: 1px solid gainsboro;
    padding: 5px 0 5px 15px;
    color: #003f99;
    position: relative;
  }
  .title-line::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 4px;
    background: #003f99;
    left: 0;
  }
}
.content-banner{
  margin: 25px 0;
  .news-title{
    padding: 0 15px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    margin-bottom: 5px;
  }
  .news-time{
    font-size: 13px;
    color: #919191;
  }
}
.news-list{
  .news-item{ 
    color: #393939;
    font-size: 15px;
    text-align: left;
    cursor: pointer;
    margin: 20px 0;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .news-item:hover{
    color: #003f99;
  }
}
</style>